
.background{
    position: relative;
    z-index: 1;
    //opacity: 0.5;
    .dot,
    .star{
        width: 14px;
        height: 14px;
        background-color: white;
        border-radius: 50%;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 0 20px 20px rgba(white, 0.1);
        opacity: 0.4;
        transform: translate(-50%,-50%);
        z-index: 1;
        &.small{
            width: 8px;
            height: 8px;
        }

        $positions: ("20vh": "25vw");
        @each $top, $left in $positions {
            &:nth-child(1){
                top: 20vh;
                left: 25vw;
            }
        }
        &:nth-child(1){
            top: 20vh;
            left: 25vw;
        }
        &:nth-child(2){
            top: 20vh;
            left: 75vw;
        }
        &:nth-child(3){
            top: 30vh;
            left: 90vw;
        }
        &:nth-child(4){
            top: 35vh;
            left: 10vw;
        }
        &:nth-child(5){
            top: 40vh;
            left: 92vw;
        }
        &:nth-child(6){
            top: 50vh;
            left: 20vw;
        }
        &:nth-child(7){
            top: 55vh;
            left: 85vw;
        }
        &:nth-child(8){
            top: 65vh;
            left: 82vw;
        }
        &:nth-child(9){
            top: 80vh;
            left: 40vw;
        }
        //star
        &:nth-child(10){
            top: 77vh;
            left: 30vw;
        }
        &:nth-child(11){
            top: 82vh;
            left: 20vw;
        }
        &:nth-child(12){
            top: 90vh;
            left: 12vw;
        }
        &:nth-child(13){
            top: 95vh;
            left: 90vw;
        }
    }
    .star{
        width: 50px;
        height: 50px;
        background-image: url('../images/star.svg');
        background-size: cover;
        background-position: center;
        background-color: rgba(white, 0.1);
    }
}