.cardHorizontal {
    a {
        display: flex;
        padding: 0.8rem;
        border-radius: 16px;
        -moz-column-gap: 15px;
        column-gap: 15px;
        background: $quaternaryColor;
        .image {
            width: 66px;
            height: 66px;
            background-position: center;
            background-size: cover;
            border-radius: 8px;
            flex: 0 0 auto;
        }
        .data {
            .title {
                font-size: 1.1rem;
                font-weight: 600;
                color: $textColor;
                line-height: 1rem;
                margin: 0 0 10px;
                line-height: 1.5rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .date {
                font-weight: 500;
                font-size: 0.8rem;
                color: $tertiaryColor;
            }
        }
    }
}

.topSection{
    .postCard{
        height: 275px;
    }
}

.postCard {
    overflow: hidden;
    border-radius: 14px;
    position: relative;
    &.cardSection {
        height: 275px;
    }
    a {
        display: flex;
        height: 100%;
        flex-direction: column;
        .imagePostCard {
            padding: 1rem;
        }
        .imageContent {
            position: relative;
            z-index: 2;
            padding: 1.2rem 1.5rem;
            height: 150px;
            width: 100%;
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            .tag {
                font-weight: 700;
                font-size: 14px;
                display: flex;
                align-items: center;
                position: absolute;
                top: .6rem;
                left: .6rem;
                z-index: 2;
                column-gap: 5px;
                .letter {
                    font-weight: 600;
                    font-size: $fontSizeTitleLabel;
                    padding: .2rem .5rem;
                    border-radius: 5px;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    background: $mainColor;
                    color: black;
                }
            }
        }
        &.firstItem {
            display: flex;
            flex-direction: row;
            align-content: stretch;
            align-items: stretch;
            .imageContent {
                display: flex;
                flex: 1;
                position: relative;
                z-index: 2;
                padding: 1.2rem 1.5rem;
                height: 100%;
                width: 100%;
                background-position: center;
                background-size: cover;
                border-radius: 10px;
            }
            .infosCard {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: space-between;
                .summary {
                    border-top: 0px;
                }
                .title {
                    padding-top: 1rem;
                    .text {
                        font-size: $fontSizeH2;
                        line-height: $fontLHeightH2;
                    }
                }
                .infosTop {
                    padding: 0px;
                    padding-bottom: 1rem;
                }
            }
        }
        .infosCard {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .infosTop {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0px 1rem .5rem 1rem;
                .date {
                    font-size: 0.9rem;
                    color: $tertiaryColor;
                }
                .icon {
                    .image {
                        width: 22px;
                        height: 22px;
                        background: $mainColor;
                    }
                }
            }
            .title {
                padding: 0px 1rem 1rem 1rem;
                font-weight: 700;
                font-size: $fontSizeSubTitle;
                line-height: $fontLHeightSubTitle;
                margin-bottom: 0px;
                color: $textColor;
            }
            .summary {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 1rem;
                border-top: 1px solid $bgColor;
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    li {
                        display: flex;
                        align-items: center;
                        column-gap: 10px;
                        font-size: 0.8rem;
                        font-weight: 600;
                        color: $textColor;
                        a {
                            color: inherit;
                        }
                        .labelInfo {
                            font-size: 0.7rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $tertiaryColor;
                        }
                    }
                }
            }
        }
    }
}