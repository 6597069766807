@import "../../default/sass/main_variables.scss";
$mainFont: "Tahoma", "Segoe UI", system-ui, -apple-system, sans-serif;
$fontSizeH1: 3rem;
$fontLHeightH1: 3.4rem;
$fontSizeSmallH1: 2rem;
$fontLHeightSmallH1: 2.5rem;
$fontHomeSizeH1: 5rem;
$fontHomeLHeightH1: 5.5rem;
$fontHomeSizeH1Small: 2.5rem;
$fontHomeLHeightH1Small: 2.5rem;
$fontSizeH2: 1.8rem;
$fontLHeightH2: 2.2rem;
$fontSizeH3: 1.5rem;
$fontLHeightH3: 2rem;
$fontSizeH5: 1.2rem;
$fontLHeightH5: 1.7rem;
$fontSizeText: 1.2rem;
$fontLHeightText: 1.8rem;
$fontSizeSmallText: 0.7rem;
$fontLHeightSmallText: 0.9rem;
$fontSizeBtn: 1rem;
$fontLHeightBtn: 1.1rem;
$fontSizeSmallBtn: 0.7rem;
$fontLHeightSmallBtn: 0.8rem;
$fontSizeBigTitle: 5rem;
$fontLHeightBigTitle: 5.5rem;
$fontSizeTitle: 2rem;
$fontLHeightTitle: 2.5rem;
$fontSizeSubTitle: 1.2rem;
$fontLHeightSubTitle: 1.6rem;
$fontSizeTitleCards: 1.28rem;
$fontLHeightTitleCards: 1.4rem;
$fontSizeTitleLabel: 0.9rem;
$fontLHeightTitleLabel: 1.2rem;
$fontSizePostP: 1.3rem;
$fontLHeightPostP: 2.6rem;
$fontSizePostPMobile: 1.1rem;
$fontLHeightPostPMobile: 2.3rem;
$fontSizeMenu: 1rem;
$fontSizeSubMenu: 1rem;
