@charset "UTF-8";
//Fonts
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap');
//@import '@fortawesome/fontawesome-free/css/all.min';
//@import 'fonts';
// Variables
@import 'variables';
// Bootstrap
//@import '~bootstrap5/dist/css/bootstrap.min';

/*!
 * Bootstrap v5.2.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

//START BOOTSTRAP
// scss-docs-start import-stack
// Configuration
@import 'bootstrap-custom';
//END BOOTSTRA
@import 'background';
@import 'partials/postCards';

/* BASE LAYOUT */

body,
html {
    font-family: $mainFont;
    -webkit-font-smoothing: antialiased !important;
    font-size: 14px;
    line-height: 18px;
    overflow-x: clip;
    background: $bgColor;
    color: $textColor;
}

.only_mobile {
    display: none !important;
}

.wrapper {
    background: $bgColor;
}

body {
    padding-top: 100px;
}

.text-right {
    text-align: right;
}

ul {
    margin: 0;
    padding: 0;
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

hr {
    background: #CBCBCB;
}

.container {
    position: relative;
    z-index: 2;
    .container {
        padding: 0;
    }
}

// Styles Default
h1 {
    font-weight: 800;
    strong {
        font-weight: 800;
    }
    font-size: $fontSizeH1;
    line-height: $fontLHeightH1;
    font-family: $mainFont;
}

h2 {
    font-size: $fontSizeH2;
    line-height: $fontLHeightH2;
    font-weight: 600;
    color: $titleColor;
    strong {
        font-weight: 700;
    }
}

h3 {
    font-size: $fontSizeH3;
    line-height: $fontLHeightH3;
    color: $titleColor;
    strong {
        font-weight: 700;
    }
}

h5 {
    font-size: $fontSizeH5;
    line-height: $fontLHeightH5;
    color: $titleColor;
}

p {
    font-size: $fontSizeText;
    line-height: $fontLHeightText;
    font-family: $mainFont;
    strong {
        font-weight: 800;
    }
}

figcaption {
    text-align: center;
    margin-top: -6px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.empty {
    padding: 100px 0;
    width: 100%;
    text-align: center;
}

.bookLogo {
    min-width: 25%;
    height: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-decoration: none;
    font-weight: 600;
    border-radius: 4px;
    overflow: hidden;
    img {
        margin: 0px;
        padding: 0px;
        width: 90%;
        max-width: 150px;
        object-fit: contain;
        max-height: 90%;
    }
}

.description,
.description p {
    font-size: 1.1rem;
    margin: 0;
    color: $tertiaryColor;
}

// Colors
.text-red {
    color: #ff5353;
}

.text-green {
    color: #96fcbd;
}

//Animations
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes fadeAnimation {
    0%,
    80%,
    100% {
        opacity: 0;
    }
    30%,
    50% {
        opacity: 1;
    }
}

//Image
img {
    max-width: 100%;
    &.notZoom {
        cursor: pointer;
        transform: none !important;
    }
}

//Notification
.notification {
    border-radius: 10px;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 999;
    padding: 10px 20px;
    &.alert-success{
        background: green;
    }
    &.alert-danger{
        background-color:#F83D55;
    }
}

//Modal
.modal-dialog {
    background: $bgColor;
    .modal-content {
        background: rgba(white, 0.1);
    }
    .modal-header,
    .modal-footer {
        border-bottom: none;
        border-top: none;
    }
    .modal-footer {
        .btn-secondary,
        .btn-outline-secondary {
            background: transparent;
            border: 1px solid $secundaryColor;
            color: $secundaryColor;
        }
        .btn-success,
        .btn-outline-success {
            border: none;
            color: $secundaryColorHoverText;
            background: $secundaryColor;
        }
    }
}

//Form
input,
textarea,
.form-control {
    width: 100%;
    min-height: 50px;
    border-radius: 10px;
    padding-left: 15px;
    border: none;
    color: white;
    font-size: 16px;
    background: $bgColor;
    &:focus {
        box-shadow: none;
        outline: none;
        background: #0D0D0D;
        color: $textColor;
    }
    &::placeholder {
        color: $textColor;
        opacity: 0.5;
    }
}

textarea {
    padding-top: 10px;
}

//HEADER
body.scroll-up header {
    transform: translateY(-110%);
}

header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 0.4s ease-in-out;
    background: $bgColor;
    .logoWebsite {
        max-height: 50px;
        width: auto;
        max-width: 100%;
        object-fit: contain;
        display: none;
    }
    .col {
        display: flex;
    }
}

.theme--default .logoSection img:nth-child(1) {
    display: block;
}

.theme--light .logoSection img:nth-child(2) {
    display: block;
}

.theme--default .siteLogoFooter img:nth-child(1) {
    display: block;
}

.theme--light .siteLogoFooter img:nth-child(2) {
    display: block;
}

//Modal
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    padding: 2rem;
    background: $mainColor;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .closeAction {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: black;
            border-radius: 50%;
            font-size: 18px;
            color: $secundaryColor;
        }
        .name {
            color: black;
            font-weight: 800;
            font-size: 20px;
        }
    }
    .body {
        margin-top: 40px;
        overflow: scroll;
        height: 80vh;
        &::-webkit-scrollbar {
            display: none;
        }
        ul {
            li {
                margin-bottom: 15px;
                border-radius: 10px;
                font-size: 16px;
                color: black;
                font-weight: 700;
                width: 100%;
                position: relative;
                a {
                    min-height: 70px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(white, 0.2);
                    border-radius: 5px;
                    padding: 10px 20px 10px 60px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    border: 3px solid black;
                    transform: translateY(-50%);
                }
                &:last-child:after {
                    border: 0px;
                }
                &.active {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 26px;
                        border-radius: 50%;
                        width: 14px;
                        height: 14px;
                        background: black;
                        transform: translateY(-50%);
                    }
                }
                .inMobileMenu {
                    &.search-box {
                        &.active {
                            width: calc(100% - 25px) !important;
                            input {
                                background: rgba(255, 255, 255, 0.2);
                                color: black;
                                font-weight: 800;
                                font-size: 1.4rem;
                                &::placeholder {
                                    color: black;
                                }
                            }
                            .search-btn {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .searchSection {
            form {
                .icon {
                    display: none;
                }
                input {
                    padding: 0.375rem 0.75rem;
                    background: white;
                    color: black;
                }
            }
            .action {
                text-align: center;
                margin-top: 20px;
                display: block;
                button {
                    width: 100%;
                    background: black;
                    margin: 0;
                    padding: 1rem 2rem;
                    color: $secundaryColor;
                }
            }
        }
    }
}

.modalMenu {
    .body {
        .nav-item {
            &:after {
                display: none;
            }
            .nav-link {
                padding: 10px 20px;
                justify-content: left;
                i {
                    margin-right: 15px;
                }
            }
        }
    }
}

// Menu
.navbar-dark {
    .navbar-toggler {
        border: 0px;
        padding: 0px;
        margin: 0px;
        .navbar-toggler-icon {
            color: $menuText;
        }
    }
}

#mainNav {
    transition: background-color 0.2s ease;
    // background-color: $menuBg;
    li {
        margin-right: 2rem;
        a {
            display: inline-block;
        }
    }
    .navbar-nav {
        align-items: center;
    }
}

#mainNav .navbar-brand {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: black;
    img {
        max-height: 34px;
        object-fit: contain;
        max-width: 100%;
    }
}

#mainNav .navbar-nav .nav-item .nav-link {
    color: $menuText;
    font-weight: 500;
    font-size: $fontSizeMenu;
    padding: 0.75rem 0;
    &:hover {
        text-decoration: none;
        color: $mainColor;
        i {
            color: $mainColor !important;
        }
    }
}

#mainNav .navbar-nav .nav-item .nav-link.active {
    text-decoration: none;
    color: $mainColor!important;
}

nav .dropdown-menu {
    margin: 0;
    font-size: $fontSizeSubMenu;
    max-height: 340px;
    overflow-y: auto;
    position: relative;
    z-index: 1000;
    background-color: $quaternaryColor;
}

nav .dropdown-item {
    font-size: $fontSizeSubMenu;
    font-weight: 500;
    padding: 0.4rem 1.2rem;
    background: transparent;
    color: $menuText;
}

nav .dropdown-item:hover,
nav .dropdown-item:focus {
    text-decoration: none;
    background: transparent;
    color: $mainColor;
}


/* NAV */

@media only screen and (min-width:769px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
    .dropdown-submenu {
        position: relative !important;
    }
    .dropdown-submenu>.dropdown-menu {
        top: 0 !important;
        left: 100% !important;
        margin-top: -6px !important;
        margin-left: -1px !important;
        border-radius: 0 !important;
    }
    .dropdown-submenu:hover>.dropdown-menu {
        display: block !important;
    }
    .dropdown-submenu>a:after {
        display: block;
        content: "f105";
        font-family: 'FontAwesome';
        margin-top: -18px;
        right: 15px;
        position: absolute;
        font-weight: 300;
    }
}

// SearchBox
.search-box {
    position: relative;
    width: 0px;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    padding-right: 25px;
}

.search-box.active {
    width: 250px;
}

.search-box.active input {
    opacity: 1;
}

.search-box input {
    height: 100%;
    width: calc(100% + 50px);
    border: none;
    font-size: $fontSizeMenu;
    border-radius: 50px;
    padding: 0 60px 0 20px;
    outline: none;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: $textColor;
    background: $quaternaryColor;
}

.search-box .search-btn {
    position: absolute;
    height: 50px;
    width: 50px;
    color: $menuText;
    top: 50%;
    right: -25px;
    transform: translateY(-50%);
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 2;
    background: $quaternaryColor;
    .closeIcon {
        display: none;
    }
    .searchIcon {
        display: inline-block;
    }
    &:hover {
        color: $textColor;
    }
}

.search-box.active .search-btn {
    height: 50px;
    width: 50px;
    color: $mainColor;
    background: $quaternaryColor;
    .closeIcon {
        display: inline-block;
    }
    .searchIcon {
        display: none;
    }
    &:hover {
        color: $textColor;
    }
}

// Footer
footer {
    position: relative;
    z-index: 99;
    margin-top: 3rem;
    background-color: $footerBg;
    color: $textColor;
}

footer .logoFooter {
    max-height: 80px;
    width: auto;
    max-width: 100%;
}

footer .footerGrid {
    display: flex;
    width: 100%;
    padding-bottom: 2rem;
    flex-direction: row;
}

footer h5 {
    font-weight: 600;
    font-size: 15px;
}

footer .hasChild {
    font-weight: 700;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    cursor: default;
    &:hover {
        text-decoration: none !important;
        cursor: default !important;
    }
}

footer ul ul {
    margin-bottom: 15px;
}

footer a.text-grey:hover {
    text-decoration: underline;
}

footer ul li {
    margin-bottom: 8px;
    a {
        font-size: 14px;
        i {
            margin-right: 5px;
        }
    }
}

footer .footer-bottom {
    background-color: $footerBottom;
}

.footer-bottom p {
    padding-top: 15px;
    font-size: 13px;
    color: $footerBottomText;
}

.best-bonus .label {
    text-align: right;
}

footer {
    .contactInfos {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        .title {
            font-size: $fontSizeSmallText;
        }
        .item {
            a {
                font-size: $fontSizeSmallText;
                font-weight: 700;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .logoListLegal {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        .itemLogo {
            img {
                max-height: 40px;
                object-fit: contain;
            }
        }
    }
    .footerRights {
        .siteLogoFooter {
            padding: 1rem 0px 2rem 0px;
            font-size: .9rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                max-height: 50px;
                height: 40px;
                margin-right: 1rem;
                display: none;
            }
        }
    }
}

//Button
.button,
button {
    border-radius: 8px;
    font-size: $fontSizeBtn;
    line-height: $fontLHeightBtn;
    color: black;
    font-weight: 700;
    padding: 0 20px;
    min-width: 120px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;
    background: $mainColor;
    &:hover {
        opacity: 0.8;
        text-decoration: none;
        color: white;
        box-shadow: none;
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        box-shadow: none!important;
        text-decoration: none!important;
    }
    &.secundary {
        box-shadow: none;
        background: $bonusItemBtnReviewBg;
        border: 1px solid $bonusItemBtnReviewBorder;
        color: $bonusItemBtnReviewText;
    }
    &.negative {
        background: #212760;
        color: white;
        border: 1px solid #212760;
        box-shadow: none;
    }
    &.large {
        padding: 1rem 2rem;
        font-size: 1.1rem;
    }
    &.small {
        font-size: 0.9rem;
        height: 28px;
        min-width: 80px;
    }
}

//Card Section
.cardSection {
    border-radius: 16px;
    border: 2px solid $quaternaryColor;
    background: $quaternaryColor;
    background: linear-gradient(180deg, $quaternaryColor 0%, transparent 100%);
    overflow: hidden;
    height: 100%;
    position: relative;
    .col {
        display: flex;
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        h2 {
            margin-bottom: 0;
        }
    }
    .title {
        font-size: 1.3rem;
        line-height: 1.3rem;
        font-weight: 700;
        margin-bottom: 15px;
        color: $titleColor;
    }
    .name {
        font-size: 1.3rem;
        font-weight: 700;
        margin-bottom: 15px;
        color: $secundaryColor;
    }
    &.secundary {
        border: none;
        background: $widgetBg;
        color: $widgetText;
    }
}

//Slider
.slide {
    width: 100%;
    height: 100%;
    .carousel-inner {
        height: 100%;
        border-radius: 16px;
        .carousel-item {
            height: 100%;
            background-size: cover;
            background-position: center;
            .item {
                height: 350px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                h2 {
                    color: $textColor;
                    font-weight: 700;
                    font-style: italic;
                    font-size: 2.4rem;
                    margin-bottom: 20px;
                    line-height: 2.8rem;
                }
                .action {
                    display: inline-flex;
                }
            }
        }
    }
    .carousel-indicators {
        z-index: 99;
        button {
            height: 6px;
            width: 40px;
            border-radius: 12px;
            border: none;
            margin: 0 5px;
            min-width: 0;
        }
    }
    .arrows {
        position: absolute;
        top: 40px;
        right: 30px;
        display: flex;
        column-gap: 10px;
        z-index: 1000;
        .arrow {
            position: relative;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            color: #161a42;
        }
    }
}

@media (max-width: 700px) {
    .breadcrumbSection ul li:after {
        display: inline-block;
        margin-right: 0;
        padding: 0 4px;
    }
    .breadcrumbSection ul li:after {
        content: "/";
        margin-right: 4px;
    }
    .breadcrumbSection ul li span {
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
}